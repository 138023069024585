import { createRouter, createWebHistory } from 'vue-router';
import LandingView from '../views/LandingView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import DocumentationView from '../views/DocumentationView.vue';
import AboutUsView from '../views/AboutUsView.vue';
import SetupView from '../views/SetupView.vue';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: DocumentationView
  },
  {
    path: '/aboutus',
    name: 'about us',
    component: AboutUsView
  },
  {
    path:'/setup',
    name: 'setup',
    component: SetupView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  "navigationFallback": {
    "rewrite": "/index.html",
    "exclude": ["/images/*.{png,jpg,gif}", "/css/*"]
  }
})

export default router
