<template>
  <div class="docs-nav-item-wrapper">
    <div class="docs-nav-item-bg"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DocsNavItem',
  data () {
    return {
      active: false
    }
  }
}
</script>

<style scoped>
.docs-nav-item-wrapper {
  border-radius: 8px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 8px;

  cursor: pointer;
  position: relative;
  overflow: hidden
  ;
}

.docs-nav-item-wrapper:hover .docs-nav-item-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: .2;
  
  background-color: var(--color-brand-tertiary);
}
</style>