<template>
  <div class="documentation-content">
    <h1>How do I Configure a Layout?</h1>
    <p>Layouts are designed on Proto Control's Website <a href="/">protocontrol.dev</a></p>

    <span class="docs-highlight">Sign Into Your ProtoControl Account</span>

    <p>Click the “Get Started” Button in the upper right hand corner.</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p class="docs-highlight">Select the DeviceID you want to Design a Layout for.</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p>A blank layout design page will open.</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p class="docs-highlight">Insert a List of Available Components and their Customizable Parameters</p>

    <p>You can drag and drop components into the layout design area. Components are resizable. You can modify the dimensions of a component by [insert description].</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p>When you modify the dimensions of a component, the dimensions of the surrounding components may automatically change to accommodate your changes.</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p>If you would like to remove a component do the following [insert description]</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p class="docs-highlight">What are the other parameters we can adjust when designing a layout?</p>

    <h1>How do I Upload a Layout?</h1>

    <p>
      Each ProtoControl Panel comes with a unique DeviceID. This DeviceID is synced to your account. 
      When you finish a Layout for a particular DeviceID, the Layout is uploaded to the Device it 
      was designed for.
    </p>

    <p>
      When you are satisfied with your design, make sure Panel is powered on and connected to wi-fi. 
      Then click the “Generate” button on the website. 
    </p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <h1>How do I Update a Layout?</h1>

    <p>
      The process of modifying a Layout once it has been uploaded to the device is similar to configuring 
      a new layout. 
    </p>

    <p>
      First, make sure you are logged in to your ProtoControl Account. Select the DeviceID you want to 
      edit. 
    </p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p>The previous Layout will open.</p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <p>
      Make your desired modifications (see the “How do I Configure a Layout” section for information 
      about customizing your Layout).
    </p>

    <p>
      When you are satisfied with your design, click the “Generate” button and the new Layout will 
      override the previous one and upload to your device. (add instructions to make sure device can 
      receive new layout. Also, should the old Layout still be saved to the account? And there be a 
      way to say which Layout you want available on the DeviceID?)
    </p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <h1>How do I Set Up ProtoControl with my MCU?</h1>

    <p>ProtoControl is Compatible with MCUs that meet the following specifications:</p>
    <ul>
      <li>S</li>
      <li>S</li>
      <li>S</li>
    </ul>

    <p>
      Our Github page was a folder titled “Downloadable Library Files”. The folder 
      contains scripts for various families of MCUs. 
    </p>

    <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />

    <h1>Language References</h1>

    <p>[Insert paragraph about how to decode uart signals, overview of id value breakdown, example code]</p>
</div>
</template>

<script>
export default {
  name: 'FAQView'
}
</script>

<style>

</style>