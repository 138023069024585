<template>
  <div id="setup-wrapper">
    <h1>setup guide</h1>
  </div>
</template>

<script>
export default {
  name: 'SetupView'
}
</script>