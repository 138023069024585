<template>
  <div id="about-us-wrapper">
    <h1>about us</h1>
  </div>
</template>

<script>
export default{
  name: 'AboutUsView'
}
</script>