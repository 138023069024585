<template>
  <div class="fun-background-wrapper">
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "FunBackground",
  // vue props
  // userSeed: the seed for the background, randomized if not used
  props: ['userSeed'],
  data() {
    return {
      seed: this.genSeed(),
      progress: 0
    }
  },
  methods: {
    genSeed () {
      const seed = [];
      for (let i = 0; i < 100; i++) {
        seed.push(Math.random());
      }
      return seed;
    },
    calcBackground (seed) {
      const lineData = [];
      seed.forEach(percent => {
        lineData.push(this.calcLine(percent))
      });
      return lineData;
    },
    calcLine (percent) {
      const width = this.$el.offsetWidth;
      const height = this.$el.offsetHeight;
      
      const line = {}

      percent = `${percent}`;
      const pLine = percent.substring(0, percent.length);
      const pAngle = "0." + percent.substring(percent.length / 2, percent.length);

      const perimeter = width * 2 + height * 2;
      const location = perimeter * pLine;

      if (location < width) { //top
        line.coords = {x: location, y: 0}
        line.angle = pAngle * 180 + 90
      } else if (location < width + height) { //right
        line.coords = {x: width, y: location - width}
        line.angle = pAngle * 180 + 180
      } else if (location < width * 2 + height) { //bottom
        line.coords = {x: location - width - height, y: height }
        line.angle = pAngle * 180 + 270
      } else { //left
        line.coords = {x: 0, y: location - width * 2 - height}
        line.angle = pAngle * 180
      }

      return line
    },
    drawBackground (lineData) {
      const canvas = this.$el.querySelector('#canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = this.$el.offsetWidth;
      canvas.height = this.$el.offsetHeight;
      console.log(canvas.width, canvas.height);
      

      ctx.strokeStyle = getComputedStyle(canvas).getPropertyValue('--color-brand-tertiary');
      ctx.lineWidth = 1;

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      lineData.forEach(line => {
        ctx.moveTo(line.coords.x, line.coords.y);
        ctx.lineTo(line.coords.x + (this.progress * Math.cos(line.angle * (Math.PI / 180))), line.coords.y + (this.progress * Math.sin(line.angle * (Math.PI / 180))));
        ctx.stroke();
      });

    }
  },
  mounted() {
    if (this.userSeed) this.seed = this.userSeed;

    const interval = setInterval(() => {
      this.drawBackground(this.calcBackground(this.seed))
      this.progress += 20;
      if (this.progress > 5000) clearInterval(interval);
    }, 20);
  }
}
</script>

<style scoped>
.fun-background-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#canvas {
  width: 100%;
  height: 100%;
  color: var(--color-brand-tertiary);
}
</style>