<template>
  <div class="documentation-content" id="datasheet-content-wrapper">
    <div class="datasheet-content" id="datasheet-content-1">
      <h2><i>Overview</i></h2>

      <b>Features</b>
      <ol>
        <li>Compatible with industry standard microcontrollers</li>
        <li>UART port</li>
        <li>2.8” Capacitive TouchScreen Display</li>
        <li>4 I2C Ports for Hardware Peripherals</li>
        <li>Magnetic Connectors for Hot-Swapability</li>
      </ol>

      <b>Applications</b>
      <ol>
        <li>Testbench for electrical components</li>
        <li>Display and Controller for Embedded Systems</li>
        <li>Prototyping device for hobbyists</li>
        <li>Display for network of analog sensors</li>
      </ol>

      <b>Device Information</b>
      <table>
        <tr>
          <td>Parameter</td>
          <td>Value</td>
        </tr>
        <tr>
          <td>Voltage</td>
          <td>5V</td>
        </tr>
        <tr>
          <td>Max Current Draw</td>
          <td>200mA</td>
        </tr>
        <tr>
          <td>Panel Dimensions (LxWxH)</td>
          <td>108 x 82 x 22</td>
        </tr>
      </table>

      <p class="docs-highlight">Serial Communication Requirements:</p>

      <img class="docs-image" alt="A cute twink smiling at the camera all coy and shy." src="@/assets/docs/faq/1.png" />
    </div>
    <div class="datasheet-content" id="datasheet-content-2">
      Protocontrol is a plug and play panel with a start-to-finish user interface development experience. 
      Protocontrol consists of a touchscreen display, four I2C ports for hardware peripherals, 
      and a UART channel to communicate with the User's device. Customizing the touchscreen's Profile 
      is as simple as using the website to drag and drop icons onto a grid that maps to the touch screen. 
      Both the digital and hardware peripherals can be used as controllers for the user system and the 
      touchscreen can also be used to display values from the user system. Protocontrol is compatible 
      with commonly used microcontrollers such as Arduino. This device is ideal for engineers who need 
      to quickly develop a user interface to test their product and for hobbyists who need a system that 
      is easy to use and can help manage complexity.

    </div>
  </div>
</template>

<script>
export default {
  name: 'DatasheetView'
}
</script>

<style>
#datasheet-content-wrapper {
  display: flex;
}

.datasheet-content {
  display: flex;
  flex-direction: column;
  
  width: 50%;
}

table {
  border-collapse: collapse;
}

td {
  border: 1px solid black;
}
</style>